<template>
  <el-main>
    <div class="title">1、用户信息</div>
    <div class="userInfo content" v-if="user_info.user_info">
      <UserInfo :name="user_info.user_info.nickname" :avatar="user_info.user_info.avatar"></UserInfo>
      <div class="createTime ml60 nowarp">注册时间：{{ user_info.user_info.create_time }}</div>
      <div class="areaBelong ml60 nowarp">所属区域：{{ user_info.user.province + user_info.user.city + user_info.user.district }}</div>
    </div>
    <div class="prevTitle" v-if="user_info.distribution">
      <div class="nowarp">推广员用户姓名：{{ user_info.distribution.name }}</div>
      <div class="ml60 nowarp">推广员电话：{{ user_info.distribution.mobile }}</div>
    </div>
    <div class="title">2、回收信息</div>
    <div class="content" v-if="user_info.user">
      <el-card class="box-card">
        <div class="info">{{ Number(user_info.user.total_order_count).toFixed(0) }}</div>
        <div>回收订单数</div>
      </el-card>
      <el-card class="box-card">
        <div class="info">￥{{ Number(user_info.user.total_order_amount).toFixed(2) }}</div>
        <div>回收订单金额</div>
      </el-card>
      <el-card class="box-card">
        <div class="info">{{ user_info.user.energy_value }}</div>
        <div>公益值</div>
      </el-card>
      <el-button type="primary" @click="lookDrder(user_info.user_info.nickname)">查看订单</el-button>
    </div>
    <div class="title">3、收货地址</div>
    <el-table :data="user_info.address" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <el-table-column prop="name" label="姓名" align="center" width="150px"></el-table-column>
      <el-table-column prop="mobile" label="手机号码" align="center" width="150px"></el-table-column>
      <el-table-column label="省市区域" align="center">
        <template v-slot="{ row }">
          {{ row.province + row.city + row.district }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="详细地址" align="center">
        <template v-slot="{ row }">
          <div style="white-space: nowrap">{{ row.address }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="is_default" label="是否默认" align="center">
        <template v-slot="{ row }">
          {{ row.is_default ? '是' : '否' }}
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
import UserInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate.js';
export default {
  components: {
    UserInfo,
  },
  data() {
    return {
      getDateformat,
      user_id: 0,
      user_info: {},
    };
  },
  created() {
    if (this.$route.query.id) {
      this.user_id = this.$route.query.id;
    } else {
      this.$message.warning('缺少参数');
      this.$router.push('/user/O2OuserList');
    }
    this.init();
  },
  methods: {
    init() {
      this.$axios
        .post(this.$api.O2O.recycler.userInfo, {
          id: this.user_id,
        })
        .then(res => {
          if (res.code === 0) {
            this.user_info = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    lookDrder(name) {
      this.$router.push('/order/recyclingOrder?name=' + name);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .title {
    line-height: 45px;
    font-weight: bold;
  }
  .prevTitle {
    font-size: 14px;
    margin-left: 100px;
    display: flex;
    align-items: center;
  }
  .content {
    font-size: 14px;
    padding: 20px 0;
    display: flex;
    align-items: center;

    .nowarp {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .box-card {
      flex-shrink: 0;
      width: 280px;
      margin-right: 50px;
      .info {
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
  .ml60 {
    margin-left: 60px;
  }
}
</style>
